import { combineReducers } from 'redux';
import { INITIALIZE_ALERTS, DROP_ALERT } from './action.es6';

const alerts = (
  state = {
    items: [],
  },
  action,
) => {
  switch (action.type) {
    case INITIALIZE_ALERTS:
      return {
        items: action.alerts.map((alert) => alert[1]),
      };
      break;

    case DROP_ALERT:
      return { items: state.items.shift() };
      break;

    default:
      return state;
  }
};

const rootReducer = combineReducers({
  alerts,
});

export default rootReducer;

// ポリフィル
import 'pollyfill.es6';

import Rails from '@rails/ujs';
Rails.start();

// EventEmitter
import EventEmitter from 'eventemitter3';
window.emitter = new EventEmitter();

import mount from 'components/mount';

// アラート
import Alert from 'products/components/alert';
// サンプル
import Sample from 'products/components/sample';
// ログイン導入
import Gateway from 'products/components/gateway';
// レッスン動画
import Player from 'products/components/player';

mount({ Alert, Sample, Gateway, Player });

// ドロワーメニュー
import 'products/scripts/drawer.es6';

// ページ遷移後スクロール
import 'products/scripts/transited_scroll.es6';

// ドロワーメニュー開く
window.addEventListener(
  'load',
  () => {
    const body = document.body;
    document.querySelectorAll('.js-drawerOpen').forEach((trigger) => {
      trigger.addEventListener('click', () => {
        body.dataset.menuOpen = body.dataset.menuOpen == 'true' ? 'false' : 'true';
      });
    });
  },
  false,
);

import React from 'react';
import videojs from 'video.js';

export default class Player extends React.Component {
  /**
   *  コンストラクタ
   *  @version 2018/02/14
   *  @author ayana.kusu
   */
  constructor(props) {
    super(props);
    // state
    this.state = {
      controls: true,
      sources: [
        {
          src: `https://codecampkids-online.s3.ap-northeast-1.amazonaws.com/products/project/lp/level${props.level.id}.mov`,
          type: 'video/mp4',
        },
      ],
      aspectRatio: '4:3',
    };
  }

  /**
   *  初期描画直後に一度だけ実行
   *  @version 2018/02/14
   *  @author ayana.kusu
   */
  componentDidMount() {
    this.player = videojs(this.videoNode, this.state);
    this.player.currentTime(this.props.period);
  }

  /**
   *  表示処理
   *  @version 2018/02/14
   *  @author ayana.kusu
   */
  render() {
    const { level } = this.props;

    return (
      <div>
        <div data-vjs-player>
          <video ref={(node) => (this.videoNode = node)} className='video-js'></video>
        </div>
      </div>
    );
  }
}

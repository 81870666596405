(() => {
  /**
   * GETパラメータ展
   */
  const purseParams = (string) => {
    // 変数用意
    const params = {};
    // 文字列展開
    string.split('&').forEach((param) => {
      // プロパティ展開
      const prop = param.split('=');
      // 挿入
      params[prop[0]] = prop[1];
    });
    // 返却
    return params;
  };

  window.addEventListener('load', () => {
    const params = purseParams(window.location.search.replace('?', ''));
    if (!params.scroll) return;

    const target = document.querySelector(`#${params.scroll}`);
    if (!target) return;

    target.scrollIntoView();
  });
})();
